import ajax from '../assets/uilt/ajax';
const batchApply = {
  /**根据ID查询申请 */
  getOne: req => { return ajax.get({ url: "/back/batch-apply/get-one", param: req.param, success: req.success }) },
  /**分页获取申请列表 */
  getPage: req => { return ajax.get({ url: "/back/batch-apply/get-page", param: req.param, success: req.success }) },
  /**分页查询批量汇款、转账导入的数据 */
  pageBatchRemittanceTransferImport: req => { return ajax.get({ url: "/back/batch-apply/page-batch-remittance-transfer-import", param: req.param, success: req.success }) },
  /**根据ID更新审核状态 */
  checkStatus: req => { return ajax.post({ url: "/back/batch-apply/check-status", param: req.param, success: req.success }) },
}

export default batchApply;