<template>
  <el-card class="box-card full-screen batch-operation-review">
    <div ref="pageHead" class="table-page-head">
      <el-form :inline="true" :model="queryForm">
        <el-form-item :label="$t('transactionInfoId')" size="small">
          <el-input v-model="queryForm.applyCode"></el-input>
        </el-form-item>
        <el-form-item v-if="loginInfo.type!='customerLogin'" :label="$t('accountType')">
          <el-select v-model="queryForm.accountType" :placeholder="$t('all')" size="small">
            <el-option :label="$t('all')" value=""></el-option>
            <el-option :label="$t('individualAccount')" value="5"></el-option>
            <el-option :label="$t('companyAccount')" value="6"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item :label="$t('dealType') ">
          <el-select v-model="queryForm.tranType" :placeholder="$t('all')" size="small">
            <el-option :label="$t('all')" value=""></el-option>
            <el-option :label="$t('batchOfRemittance')" value="1"></el-option>
            <el-option :label="$t('batchTransferAccounts')" value="2"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item :label="$t('checkStatus')">
          <el-select v-model="queryForm.status" :placeholder="$t('all')" size="small">
            <el-option :label="$t('all')" value=""></el-option>
            <el-option :label="$t('checkPending')" value="0"></el-option>
            <el-option :label="$t('getApproved')" value="1"></el-option>
            <el-option :label="$t('notApproved')" value="2"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" size="small" @click="getList" :loading="isLoading"><i class="el-icon-search"></i> {{$t('query')}}</el-button>
        </el-form-item>
      </el-form>
    </div>

    <div class="data-table" :style="{top:queryTableHeight,height:`calc(100% - ${queryTableHeight} - 70px)`}">
      <el-table :data="tableData.records" v-loading="isLoading" element-loading-background="rgba(255,255,255,0.4)" size="mini" height="100%">
        <el-table-column type="selection" fixed="left" width="50"></el-table-column>
        <el-table-column prop="applyCode" :label="$t('transactionInfoId')" fixed="left" width="160"></el-table-column>
        <el-table-column prop="account" :label="$t('transaction account')" fixed="left" width="160"></el-table-column>
        <el-table-column v-if="loginInfo.type!='customerLogin'" prop="accountType" :label="$t('accountType')" fixed="left" width="120">
          <template slot-scope="scope">
            <span v-if="scope.row.accountType==5">{{$t('individualAccount')}}</span>
            <span v-else-if="scope.row.accountType==6">{{$t('companyAccount')}}</span>
          </template>
        </el-table-column>
        <el-table-column prop="tranType" :label="$t('dealType')" fixed="left" width="120">
          <template slot-scope="scope">
            <span v-if="scope.row.tranType==1">{{$t('batchOfRemittance')}}</span>
            <span v-else-if="scope.row.tranType==2">{{$t('batchTransferAccounts')}}</span>
          </template>
        </el-table-column>
        <el-table-column prop="amount" :label="$t('transactionAmount')" width="140">
          <template slot-scope="scope">
            <span class="franc">₣</span>{{scope.row.amount.formatMoney(0)}}
          </template>
        </el-table-column>
        <el-table-column prop="excelName" :label="$t('transaction EXCEL name')" show-overflow-tooltip width="200"></el-table-column>
        <el-table-column prop="createTime" :label="$t('transaction time')" width="140">
          <template slot-scope="scope">
            {{$manba(scope.row.createTime).format('ymdhms')}}
          </template>
        </el-table-column>

        <!-- 、0待审核 1审核通过 2审核失败(大客户只需要自己财务+自己经理审核，这里加了一个审核状态) -->
        <el-table-column v-if="loginInfo.type=='customerLogin'" prop="checkStatus" :label="$t('checkStatus')" width="130">
          <template slot-scope="scope">
            <span v-if="scope.row.checkStatus==0" class="text-primary">{{$t('checkPending')}}</span>
            <span v-else-if="scope.row.checkStatus==1" class="text-success">{{$t('getApproved')}}</span>
            <span v-else-if="scope.row.checkStatus==2" class="text-danger">{{$t('notApproved')}}</span>
          </template>
        </el-table-column>
        <!-- 、0待审核 1审核通过 2审核失败 -->
        <el-table-column v-else prop="status" :label="$t('checkStatus')" width="130">
          <template slot-scope="scope">
            <span v-if="scope.row.status==0" class="text-primary">{{$t('checkPending')}}</span>
            <span v-else-if="scope.row.status==1" class="text-success">{{$t('getApproved')}}</span>
            <span v-else-if="scope.row.status==2" class="text-danger">{{$t('notApproved')}}</span>
          </template>
        </el-table-column>

        <el-table-column prop="verifyTime" :label="$t('verifyTime')" width="140">
          <template slot-scope="scope">
            <span v-if="scope.row.verifyTime">{{$manba(scope.row.verifyTime).format('ymdhms')}}</span>
          </template>
        </el-table-column>
        <el-table-column prop="verifyRemark" :label="$t('checkRemark')+1" show-overflow-tooltip width="200" />
        <el-table-column prop="checkRemark" :label="$t('checkRemark')+2" show-overflow-tooltip width="200" />
        <el-table-column :label="$t('extend')" fixed="right" width="165">
          <template slot-scope="scope">
            <el-button size="mini" @click.stop="showDrawerRowExcel(scope.row)">{{$t('detailedInfo')}}</el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>

    <el-pagination background layout="total,sizes,prev,pager,next,jumper" :total="tableData.total||0" :page-sizes="[20, 30, 50, 100, 200]" :page-size="pageSize" :current-page="currentPage" @size-change="setPageSzie" @current-change="setCurrentChange" @prev-click="setCurrentChange" @next-click="setCurrentChange" />

    <el-drawer :visible.sync="drawerRowExcel" direction="rtl" :modal="false" :destroy-on-close="true" :wrapperClosable="false" size="1200px">
      <template #title>
        <span v-if="selectedRows.tranType == 1">{{$t('Batch remittance review')}}</span>
        <span v-if="selectedRows.tranType == 2">{{$t('Batch transfer review')}}</span>
      </template>
      <el-collapse v-model="collapseActiveNames">
        <el-collapse-item :title="$t('transaction info')" name="1">
          <el-row class="jiaoyirenxx">
            <el-col :xs="24" :sm="12" :md="8" :lg="6">
              <strong>{{$t('transaction account')}}：</strong>
              <div>{{selectedRows.account}}</div>
            </el-col>
            <el-col :xs="24" :sm="12" :md="8" :lg="6">
              <strong>{{$t('accountType')}}：</strong>
              <div v-if="selectedRows.accountType==5"> {{$t('individualAccount')}} </div>
              <div v-else-if="selectedRows.accountType==6"> {{$t('companyAccount')}} </div>
            </el-col>
            <el-col :xs="24" :sm="12" :md="8" :lg="6">
              <strong>{{$t('dealType')}}：</strong>
              <div v-if="selectedRows.tranType==1">{{$t('batchOfRemittance')}}</div>
              <div v-else-if="selectedRows.tranType==2">{{$t('batchTransferAccounts')}}</div>
            </el-col>
            <el-col :xs="24" :sm="12" :md="8" :lg="6">
              <strong>{{$t('transactionAmount')}}：</strong>
              <div v-if="selectedRows.amount"><span class="franc">₣</span>{{selectedRows.amount.formatMoney(0)}}</div>
              <div v-else><span class="franc">₣</span>--</div>
            </el-col>
            <el-col :xs="24" :sm="12" :md="8" :lg="6">
              <strong>{{$t('transaction EXCEL name')}}：</strong>
              <div>{{selectedRows.excelName}}</div>
            </el-col>
            <el-col :xs="24" :sm="12" :md="8" :lg="6">
              <strong>{{$t('transaction time')}}：</strong>
              <div>{{$manba(selectedRows.createTime).format('ymdhms')}}</div>
            </el-col>
            <el-col :xs="24" :sm="12" :md="8" :lg="6">
              <strong>{{$t('reason2')}}：</strong>
              <div>{{selectedRows.casue}}</div>
            </el-col>
            <el-col :xs="24">
              <strong>{{$t('transactionRemark')}}：</strong>
              <div>{{selectedRows.remark}}</div>
            </el-col>
          </el-row>
        </el-collapse-item>
        <el-collapse-item :title="$t('proceeds info list')" name="2">
          <!-- 转账EXCEL模板 -->
          <el-table v-if="selectedRows.tranType == 2" :data="rowExcelData" size="mini" max-height="500px" v-loading="isItemLoading">
            <el-table-column prop="cardNo" :label="$t('cardNo')" width="160"></el-table-column>
            <el-table-column prop="idCard" :label="$t('idCard')" width="160"></el-table-column>
            <el-table-column prop="phone" :label="$t('phone')" width="100"></el-table-column>
            <el-table-column prop="amount" :label="$t('amount')" width="100">
              <template slot-scope="scope">
                <span class="franc">₣</span>{{scope.row.amount.formatMoney(0)}}
              </template>
            </el-table-column>
            <el-table-column prop="applyRemark" :label="$t('remark')"></el-table-column>
          </el-table>
          <!-- 汇款EXCEL模板 -->
          <el-table v-else-if="selectedRows.tranType == 1" :data="rowExcelData" size="mini" max-height="500px" v-loading="isItemLoading">
            <el-table-column prop="name" :label="$t('firstName')" width="160"></el-table-column>
            <el-table-column prop="surname" :label="$t('lastName')" width="160"></el-table-column>
            <el-table-column prop="idCard" :label="$t('idCard')" width="160"></el-table-column>
            <el-table-column prop="phone" :label="$t('phone')" width="100"></el-table-column>
            <el-table-column prop="amount" :label="$t('amount')" width="100">
              <template slot-scope="scope">
                <span class="franc">₣</span>{{scope.row.amount.formatMoney(0)}}
              </template>
            </el-table-column>
            <el-table-column prop="remark" :label="$t('remark')"></el-table-column>
          </el-table>
        </el-collapse-item>
      </el-collapse>

      <div class="functional-zone">
        <el-form :inline="true" :model="checkForm" :rules="checkRules" size="small" label-position="top" ref="checkForm">
          <el-form-item :label="$t('checkStatus')" prop="status">
            <el-radio-group v-model="checkForm.status" :disabled="selectedRows.status!=0">
              <el-radio label="1" border>{{$t('getApproved')}}</el-radio>
              <el-radio label="2" border>{{$t('notApproved')}}</el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item :label="$t('checkRemark')" prop="verifyRemark">
            <el-input v-model="checkForm.verifyRemark" :disabled="selectedRows.status!=0"></el-input>
          </el-form-item>
          <el-form-item v-if="selectedRows.status==0" label=" ">
            <el-button type="primary" @click="onSubmitCheck('checkForm')">{{$t('subminCheckResult')}}</el-button>
          </el-form-item>
        </el-form>
      </div>
    </el-drawer>
  </el-card>
</template>

<script>
import batchApply from '@/api/batchApply';
import customerBusiness from '@/api/customerBusiness';

export default {
  name: "batchOperationReview",
  data () {
    return {
      queryForm: {},
      //table高度，动态设置
      queryTableHeight: String,
      //table显示内容
      tableData: {},
      isLoading: false,
      //分页器默认参数
      pageSize: 20,
      currentPage: 1,

      //显示行对应的EXCEL列表
      isItemLoading: false,
      selectedRows: {},
      drawerRowExcel: false,
      rowExcelData: [],
      collapseActiveNames: ['1', '2'],
      checkForm: {},
      checkRules: {
        status: [{ required: true, message: this.$t('requiredFields'), trigger: 'change' }],
        verifyRemark: [{ required: true, message: this.$t('requiredFields'), trigger: 'blur' }],
      }
    }
  },
  computed: {
    loginInfo () { return this.$store.state.globalParameter.loginInfo }
  },
  methods: {
    getList () {
      this.isLoading = true;
      if (this.loginInfo.type == 'customerLogin') {
        customerBusiness.pageBatchApply({
          param: { current: this.currentPage, size: this.pageSize, ...this.queryForm },
          success: res => {
            this.tableData = res.data || {};
            this.isLoading = false;
          }
        })
      }
      else {
        batchApply.getPage({
          param: { current: this.currentPage, size: this.pageSize, ...this.queryForm },
          success: res => {
            this.tableData = res.data || {};
            this.isLoading = false;
          }
        })
      }
    },
    setPageSzie (val) {
      this.pageSize = val;
      this.getList();
    },
    setCurrentChange (val) {
      this.currentPage = val;
      this.getList();
    },
    query () {
      this.$set(this, 'currentPage', 1)
      this.$set(this, 'tableData', {})
      this.getList();
    },

    showDrawerRowExcel (item) {
      this.$set(this, 'isItemLoading', true)
      if (this.loginInfo.type == 'customerLogin') {
        customerBusiness.pageBatchRemittanceTransferImport({
          param: { excelName: item.excelName, size: -1 }, success: res => {
            this.$set(this, 'rowExcelData', res.data?.records)
            this.$set(this, 'isItemLoading', false)
          }
        })
      } else {
        batchApply.pageBatchRemittanceTransferImport({
          param: { excelName: item.excelName, size: -1 }, success: res => {
            this.$set(this, 'rowExcelData', res.data?.records)
            this.$set(this, 'isItemLoading', false)
          }
        })
      }

      this.$set(this, 'selectedRows', item)
      if (this.loginInfo.type == 'customerLogin') {
        this.$set(this, 'checkForm', {
          status: item.checkStatus?.toString(),
          verifyRemark: item.verifyRemark
        })
      } else {
        this.$set(this, 'checkForm', {
          status: item.status?.toString(),
          verifyRemark: item.verifyRemark
        })
      }
      this.drawerRowExcel = true;
    },

    onSubmitCheck (refName) {
      this.$refs[refName].validate(valid => {
        if (!valid) return false;
        if (this.loginInfo.type == 'customerLogin') {
          customerBusiness.checkStatus({
            param: { applyId: this.selectedRows.id, ...this.checkForm }, success: res => {
              if (res.code == 200) {
                this.$set(this.selectedRows, 'status', 1);
                this.$set(this.selectedRows, 'verifyRemark', this.checkForm.verifyRemark);
                this.$message({ type: 'success', message: res.msg || this.$t('operateSuccessfully') })
              }
              else
                this.$message({ type: 'error', message: (res.msg || this.$t('operationFailure')) + ";" + res.code })
            }
          })
        } else {
          batchApply.checkStatus({
            param: { applyId: this.selectedRows.id, ...this.checkForm }, success: res => {
              if (res.code == 200) {
                this.$set(this.selectedRows, 'status', 1);
                this.$set(this.selectedRows, 'verifyRemark', this.checkForm.verifyRemark);
                this.$message({ type: 'success', message: res.msg || this.$t('operateSuccessfully') })
              }
              else
                this.$message({ type: 'error', message: (res.msg || this.$t('operationFailure')) + ";" + res.code })
            }
          })
        }
      })
    }
  },
  mounted () {
    let queryTableHeight = this.$refs.pageHead.offsetHeight + 15;
    this.queryTableHeight = queryTableHeight + 'px';
    this.getList();

    // this.$alert(this.$t('Note: After successful operation, we need to wait for the approval of the platform and the organization, and the account will be received after the approval'), this.$t('operateSuccessfully'), {
    //   confirmButtonText: this.$t('confirm'),
    // });
  }
}
</script>

<style lang="less" scoped>
.functional-zone {
  margin-top: 20px;
  /deep/.el-radio {
    margin-right: 0;
  }
  /deep/.el-form-item:last-child {
    .el-form-item__label {
      height: 32px;
    }
  }
  /deep/.el-form-item:nth-child(2) {
    width: 40%;
  }
}
.jiaoyirenxx {
  .el-col {
    margin: 5px 0;
    div {
      color: #999;
    }
  }
}
/deep/.el-collapse-item__header {
  font-weight: bold;
  font-size: 16px;
  color: #000;
}
</style>